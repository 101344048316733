import { Route, Routes, useRoutes, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { lazyRetry, useAuth } from '../functions/utilities'
import { useBoolean, useI18n } from '../functions/hooks'
import { AuthUser } from '../functions/apis/auth/authMe'
import i18n from '../functions/utilities/i18nClient'

import { protectedRoutes as protectedRoutesJpn } from './japanese/protected'
import { publicRoutes as publicRoutesJpn } from './japanese/public'
import { commonRoutes as commonRoutesJpn } from './japanese/common'
import { marudeliRoutes } from './japanese/marudeli'

import { protectedRoutes as protectedRoutesEng } from './english/protected'
import { publicRoutes as publicRoutesEng } from './english/public'
import { commonRoutes as commonRoutesEng } from './english/common'

const Component = lazyRetry(
  () => import(/* webpackChunkName: "catalog-page" */ '../pages/Component')
)

/* eslint-disable react/jsx-no-useless-fragment*/
export const AppRoutes = () => {
  const { isLocalhost } = useBoolean()
  const { isJapanese } = useI18n()
  const { user } = useAuth()

  useEffect(() => {
    i18n.changeLanguage(isJapanese ? 'jpn' : 'eng')
  }, [isJapanese])

  // ページ移動したときの値保持を維持するか判定するのための追記
  const location = useLocation()
  const [previousPath, setPreviousPath] = useState<string | null>(null)

  useEffect(() => {
    const handleLocationChange = () => {
      const excludedPath1 = '/journal/'
      const excludedPath2 = '/journal'
      const excludedPath3 = '/voucher/'
      const excludedPath4 = '/voucher'

      const currentPath = location.pathname

      if (
        (previousPath?.startsWith('/voucher') &&
          currentPath.startsWith('/journal')) ||
        (previousPath?.startsWith('/journal') &&
          currentPath.startsWith('/voucher'))
      ) {
        sessionStorage.clear()
      } else if (
        !(
          currentPath.startsWith(excludedPath1) ||
          currentPath.startsWith(excludedPath2) ||
          currentPath.startsWith(excludedPath3) ||
          currentPath.startsWith(excludedPath4)
        )
      ) {
        sessionStorage.clear()
      }

      setPreviousPath(currentPath)
    }

    handleLocationChange()
  }, [location, previousPath])

  const isAuthenticated = (user as AuthUser)?.authFlg

  // NOTE:ローカル環境でカタログページを表示するか
  const componentRoutes = isLocalhost
    ? [{ path: '/component', element: <Component /> }]
    : []

  // NOTE:共通で使うコンポーネント(トップページや利用規約など)
  const commonRoutes = isJapanese ? commonRoutesJpn : commonRoutesEng

  // NOTE:ログインユーザーか否かでのコンポーネントの出しわけ
  const routesJpn = isAuthenticated
    ? [...protectedRoutesJpn, ...marudeliRoutes]
    : publicRoutesJpn
  const routesEng = isAuthenticated ? protectedRoutesEng : publicRoutesEng

  const routes = isJapanese ? routesJpn : routesEng

  const element = useRoutes([...routes, ...commonRoutes, ...componentRoutes])

  return <>{element}</>
}
